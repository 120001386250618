<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('recharge.recharge')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()" />
        </template>
      </van-nav-bar>
<!--      <div class="info">-->
<!--        <p class="title">-->
<!--          {{ $t("recharge.curr_balance") }}({{ $t("reservation.unit") }})-->
<!--        </p>-->
<!--        <p class="value">{{ this.balance }}</p>-->
<!--      </div>-->
      <div style="margin: 12px;font-size: 20px">{{ $t("recharge.pay_way") }}</div>
      <div style="width: 100%;
      display: flex;align-items: center;justify-content: center;padding:15px;"
           @click="selectBankFnc('USDT')">
            <div style="flex: 1;display: flex;align-items: center">
              <div style="padding-right: 10px">
                <img style="width: 40px;"
                     :src="require('/src/assets/img/USDT.png')"
                     alt="">
              </div>
              <div>USDT</div>
            </div>
            <div>
              <van-icon name="arrow" />
            </div>
          </div>

      <div style="width: 100%;
      display: flex;align-items: center;justify-content: center;padding:15px;"
      @click="selectBankFnc('BTC')">
            <div style="flex: 1;display: flex;align-items: center">
              <div style="padding-right: 10px">
                <img style="width: 40px;"
                     :src="require('/src/assets/img/BTC.png')"
                     alt="">
              </div>
              <div>BTC</div>
            </div>
            <div>
              <van-icon name="arrow" />
            </div>
          </div>

      <div style="width: 100%;
      display: flex;align-items: center;justify-content: center;padding:15px;"
      @click="selectBankFnc('ETH')">
            <div style="flex: 1;display: flex;align-items: center">
              <div style="padding-right: 10px">
                <img style="width: 40px;"
                     :src="require('/src/assets/img/ETH.png')"
                     alt="">
              </div>
              <div>ETH</div>
            </div>
            <div>
              <van-icon name="arrow" />
            </div>
          </div>

      <div class="content recharge">

        <van-form @submit="onSubmit">
<!--          <div class="form-item">-->
<!--            <div class="form-item-title">{{ $t("recharge.input_money") }}</div>-->
<!--            <div style="height: 65px">-->
<!--              <van-field v-model="money" name="money" label="MXN" :placeholder="$t('recharge.input_money')" />-->
<!--            </div>-->
<!--          </div>-->

          <div class="form-item">
            <div class="form-item-title">{{$t("recharge.pay_way")}}</div>
<!--            <div style="margin: 16px">-->
<!--              <van-button round block type="info" native-type="submit">下一步</van-button>-->
<!--            </div>-->
          </div>
        </van-form>
        <van-dialog width="90%" v-model="showcz" :show-confirm-button="false" :show-cancel-button="false" class="custom-dialog">
              <div class="dialog-content">
                <!-- <div class="dialog-header">
                  <h3>充值类型</h3>
                </div> -->
                <div class="dialog-address">
                  <p>{{ pay_way }}地址</p>
                  <span class="address">{{ transferAddress }}</span>
                </div>
                <van-button  style="width: 100%;" type="primary" size="small" @click="copyAddress">
                  点击复制
                </van-button>
                <van-button  style="width: 100%;" type="primary" size="small" @click="back">
                  已充值?，点击关闭
                </van-button>
              </div>
            </van-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Form } from "vant";
import { Field } from "vant";
import { RadioGroup, Radio } from "vant";
Vue.use(Form).use(Field).use(RadioGroup).use(Radio);
export default {
  data() {
    return {
      showcz: false,
      transferAddress:"",
      balance: 0,
      pay_way: "",
      win_money: 0,
      money: "",
      personalreport: {},
    };
  },
  mounted() {
    this.balance = this.$route.params.balance;
  },
  methods: {
    selectBankFnc(type){
      this.$router.push({ path: "/selectBank",query:{payType:type} });
    },
    copyAddress() {
      const textarea = document.createElement('textarea'); // 创建一个textarea元素
      textarea.value = this.transferAddress; // 将需要复制的地址赋值给textarea
      document.body.appendChild(textarea); // 将textarea添加到文档中
      textarea.select(); // 选中textarea中的文本
      const successful = document.execCommand('copy'); // 执行复制命令
      document.body.removeChild(textarea); // 复制完成后移除textarea
      if (successful) {
        this.$toast('地址已复制');
      } else {
        this.$toast('复制失败');
      }
    },
    back() {
      return window.history.back();
    },

    //拉起支付
    onSubmit(values) {
      const money = values.money;
      if (money <= 0) {
        this.$toast(this.$t("reservation.money_err"));
        return;
      }
      if(this.pay_way=='Tron'){
        this.transferAddress = 'TMevc7ho9PDMXneM2mJXJB6Uymr7Rw3jPG'
      }
      if(this.pay_way=='Btb'){
        this.transferAddress = 'bc1pgctm70wwtjvv92r865pqxqr2gjcan2ejdadaz6gn5y9zayk3tf0qj4hdm6'
      }
      if(this.pay_way=='Ytl'){
        this.transferAddress = '0x5f44f732C791Bde42d602c2858fB9C37be67b854'
      }
      this.$http({
        method: "post",
        data: {
          pay_way: this.pay_way,
          money: this.money,
        },
        url: "recharge",
      }).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.$toast(res.msg);
          this.showcz = true
          // window.location.href = res.data.pay_url;
          //   this.personalreport = res.data;
          //   this.win_money =
          //     this.personalreport.win_money - this.personalreport.play_money;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },

    getPersonalreport() {
      this.$http({
        method: "get",
        url: "user_get_personalreport",
      }).then((res) => {
        if (res.code === 200) {
          this.personalreport = res.data;
          this.win_money =
            this.personalreport.win_money - this.personalreport.play_money;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ path: "/Login" });
    } else {
      this.getPersonalreport();
    }
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.container .header {
  //background: linear-gradient(270deg, #e6c3a1, #7e5678);
}

.recharge {
  padding: 10px 30px;
}

.van-cell {
  line-height: 65px !important;
}

.van-button {
  height: 87px !important;
}

.van-button__text {
  color: #fff !important;
}

/deep/.van-radio__icon {
  font-size: 30px !important;
}

/deep/.van-radio__label {
  margin-left: 25px !important;
  font-size: 35px !important;
}

/deep/.van-radio {
  height: 65px !important;
}

.form-item {
  margin-top: 40px;
}

.form-item-title {
  font-size: 36px;
  font-weight: bold;
  color: #999;
  margin-bottom: 20px;
}

.recharge span {
  font-size: 4vw;
  color: #868686;
  font-weight: 500;
}

.container .header .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 10px;
  margin: auto;
}

.container .header .info .title {
  font-size: 25px;
  color: #e5e5e5;
}

.container .header .info .value {
  margin: 10px auto;
  color: #fff;
  font-size: 50px;
  border-bottom: 1px solid #fff;
}

.container .header .info .tip {
  font-size: 30px;
  color: #e5e5e5;
}

.container .content {
  flex: 1;
  background: #f2f2f5;
}


// .custom-dialog {
//   max-width: 400px;
// }
.dialog-content {
  padding: 20px;
  text-align: center;
}
.dialog-header h3 {
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  color: #333;
}
.dialog-address {
  margin: 15px 0;
  font-size: 30px;
}
.address {
  display: block;
  font-weight: bold;
  color: #007aff; /* 链接颜色 */
  word-break: break-all; /* 防止地址过长产生溢出 */
}
.van-button {
  margin-top: 20px;
}
</style>
