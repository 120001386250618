<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('recharge.recharge')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()" />
        </template>
      </van-nav-bar>

      <div class="inputContent">
          <div>
            <span>{{$t("recharge.pay_way")}}</span>
            <div class="inputItem">
              <div CLASS="inputson">
                {{paramsType.payType}}
              </div>
            </div>
          </div>
          <div>
            <span>Deposit Address</span>
            <div class="inputItem">
              <div CLASS="inputson">
                {{transferAddress}}
              </div>
              <div style="color: #007aff" @click="copyAddress">{{$t("recharge.copy")}}</div>
            </div>
          </div>
        <div>
            <span>Deposit Amount</span>
            <div class="inputItem">
              <div CLASS="inputson">
                <van-field v-model="money"  label="$"   type="number"
                           placeholder="Please enter the deposit amount" />
              </div>
            </div>
          </div>
        <div v-if="paramsType.payType === 'BTC' || paramsType.payType === 'ETH'">
            <span>Deposit Address</span>
            <div class="inputItem">
              <div CLASS="inputson">
                {{moneys}}
              </div>
              <div v-if="paramsType.payType === 'BTC'">BTC/USDT</div>
              <div  v-if="paramsType.payType === 'ETH'">ETH/USDT</div>
            </div>
          </div>
        <div style="margin: 16px;font-size: 20px">
              <van-button round block type="info" @click="onSubmit" native-type="submit">{{$t("recharge.pay_ok")}}</van-button>
            </div>
      </div>
      <div class="content recharge">
        <img style="width: 80%" :src="$store.state.baseurl + transferImg" alt="" >
      </div>
      <van-dialog width="90%" v-model="showcz" :show-confirm-button="false"
                  :show-cancel-button="false" class="custom-dialog">
              <div class="dialog-content">
                <div class="dialog-address">
                  <span>{{ pay_way }}{{$t("recharge.address")}}:</span>
                  <span class="address">{{ transferAddress }}</span>
                </div>
                <div class="content recharge">
                  <img style="width: 90%" :src="$store.state.baseurl + transferImg" alt="" >
                </div>
                <van-button  style="width: 100%;" type="primary" size="small" @click="copyAddress">
                  {{$t("recharge.click_copy")}}
                </van-button>
                <van-button  style="width: 100%;" type="primary" size="small" @click="back">
                 {{$t("recharge.in_balance")}}
                </van-button>
              </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Form } from "vant";
import { Field } from "vant";
import { RadioGroup, Radio } from "vant";
Vue.use(Form).use(Field).use(RadioGroup).use(Radio);
export default {
  data() {
    return {
      value1:"",
      showcz: false,
      balance: 0,
      pay_way: "",
      win_money: 0,
      money: "",
      personalreport: {},
      paramsType:"",
    };
  },
  computed:{
    // eslint-disable-next-line vue/return-in-computed-property
    moneys() {
      if(this.paramsType.payType==='BTC'){
        return (this.money/this.$store.state.baseInfo.btc_usd).toFixed(8)
      }
      if(this.paramsType.payType==='ETH'){
        return (this.money/this.$store.state.baseInfo.eth_usd).toFixed(8)
      }
      // return (this.value1/7).toFixed(2)
    },
     transferAddress() {
      // 从路由查询参数获取 payType
      const payType = this.$route.query.payType;
      // 根据 payType 动态返回 transferAddress
      if (payType === 'USDT') {
        return this.$store.state.baseInfo.usdt;
      }
      if (payType === 'ETH') {
        return this.$store.state.baseInfo.eth;
      }
      if (payType === 'BTC') {
        return this.$store.state.baseInfo.btc;
      }
      return null; // 如果不匹配，返回默认值
    },
    transferImg() {
      // 从路由查询参数获取 payType
      const payType = this.$route.query.payType;
      // 根据 payType 动态返回 transferAddress
      if (payType === 'USDT') {
        return this.$store.state.baseInfo.usdtimg;
      }
      if (payType === 'ETH') {
        return this.$store.state.baseInfo.ethimg;
      }
      if (payType === 'BTC') {
        return this.$store.state.baseInfo.btcimg;
      }
      return null; // 如果不匹配，返回默认值
    },
  },
   created() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ path: "/Login" });
    } else {
      this.paramsType = this.$route.query
      if(this.paramsType.payType=='Tron'){
        this.transferAddress = 'TMevc7ho9PDMXneM2mJXJB6Uymr7Rw3jPG'
      }else if (this.paramsType.payType=='Btb') {
        this.transferAddress = 'bc1pgctm70wwtjvv92r865pqxqr2gjcan2ejdadaz6gn5y9zayk3tf0qj4hdm6'
      } else if(this.paramsType.payType=='Ytl') {
        this.transferAddress = '0x5f44f732C791Bde42d602c2858fB9C37be67b854'
      }
    }
  },
  mounted() {
    this.balance = this.$route.params.balance;
  },
  methods: {
    copyAddress() {
      const textarea = document.createElement('textarea'); // 创建一个textarea元素
      textarea.value = this.transferAddress; // 将需要复制的地址赋值给textarea
      document.body.appendChild(textarea); // 将textarea添加到文档中
      textarea.select(); // 选中textarea中的文本
      const successful = document.execCommand('copy'); // 执行复制命令
      document.body.removeChild(textarea); // 复制完成后移除textarea
      if (successful) {
        this.$toast('地址已复制');
      } else {
        this.$toast('复制失败');
      }
    },
    back() {
      return window.history.back();
    },

    //拉起支付
    onSubmit() {
      // const money = values.money;
      if (this.money <= 0) {
        this.$toast(this.$t("reservation.money_err"));
        return;
      }
      this.$http({
        method: "post",
        data: {
          pay_way: this.$route.query.payType,
          money: this.money,
        },
        url: "recharge",
      }).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.$toast(res.msg);
          this.showcz = true
          // window.location.href = res.data.pay_url;
          //   this.personalreport = res.data;
          //   this.win_money =
          //     this.personalreport.win_money - this.personalreport.play_money;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },

  },

};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.container .header {
  //padding: 20px;
  //background: linear-gradient(270deg, #e6c3a1, #7e5678);
}

.recharge {
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.van-cell {
  line-height: 65px !important;
}

.van-button {
  height: 87px !important;
}

.van-button__text {
  color: #fff !important;
  font-size: 24px;
}

/deep/.van-radio__icon {
  font-size: 30px !important;
}

/deep/.van-radio__label {
  margin-left: 25px !important;
  font-size: 35px !important;
}

/deep/.van-radio {
  height: 65px !important;
}

.form-item {
  margin-top: 40px;
}

.form-item-title {
  font-size: 36px;
  font-weight: bold;
  color: #999;
  margin-bottom: 20px;
}

.recharge span {
  font-size: 4vw;
  color: #868686;
  font-weight: 500;
}

.container .header .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 10px;
  margin: auto;
}

.container .header .info .title {
  font-size: 25px;
  color: #e5e5e5;
}

.container .header .info .value {
  margin: 10px auto;
  color: #fff;
  font-size: 50px;
  border-bottom: 1px solid #fff;
}

.container .header .info .tip {
  font-size: 30px;
  color: #e5e5e5;
}

.container .content {
  flex: 1;
  background: #f2f2f5;
}


// .custom-dialog {
//   max-width: 400px;
// }
.dialog-content {
  padding: 20px;
  text-align: center;
}
.dialog-header h3 {
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  color: #333;
}
.dialog-address {
  margin: 15px 0;
  font-size: 30px;
}
.address {
  display: block;
  font-weight: bold;
  color: #007aff; /* 链接颜色 */
  word-break: break-all; /* 防止地址过长产生溢出 */
}
.van-button {
  margin-top: 20px;
}

.inputItem{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #bbb;
  padding:20px 15px;
  border-radius: 8px;
  margin: 30px 0;
}
.inputson{
  width: 85%;
  overflow-wrap: anywhere;
}
::v-deep .van-cell{
  background-color: #f2f2f5;
  padding: 0px;
  line-height: 20px !important;
}
::v-deep .van-field__label{
  width: 30px;
  font-size: 36px !important;
  margin-top: 6px !important;
}
::v-deep .van-field__control{
  font-size: 25px !important;
}
.inputContent{
  padding:  0 20px;
}
</style>
